import React from "react";
import notFoundImage from "../assets/images/Oral-Hygiene.jpeg";

const NotFound = () => {
  return (
    <>
      <div className="container d-flex justify-content-center mb-5 mt-5">
        <h1 style={{ color: "#104435" }}>Sorry!! We couldn't find that page !</h1>
      </div>
      <div className="container d-flex justify-content-center mb-5 mt-5">
        <h1>go to AK Dental <a href="https://www.cdc.gov/oralhealth/conditions/periodontal-disease.html">Home Page</a></h1>
      </div>
      <div className="container d-flex justify-content-center">
        <img src={notFoundImage} alt="Oral Hygene" className="img-fluid" />
      </div>
      <br />
      <br />
      <br />
    </>
  );
};

export default NotFound;
