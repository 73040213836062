import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css";

// views
import HomePage from "./views/HomePage";
import OurTeam from "./views/OurTeam";
import DentalCleanings from "./views/dentalCleanings";
import Fillings from "./views/DentalFillings";
import OralSurgery from "./views/OralSurgery";
import DentalCrown from "./views/DentalCrowns";
import RootCanal from "./views/RootCanal";
import YourFirstVisit from "./views/YourFirstVisit";
import Insurance from "./views/Insurance";
import Specials from "./views/Specials";
import AppointmentSlots from "./views/Appointment-Slots";
import AppointmentDetails from "./views/Appointment-Details";
import AppointmentConfirmation from "./views/Appointment-Confirmation";
import TnC from "./components/TermsAndConditions";
import NotFound from "./views/NotFound";

// components
import Header from "./components/Header";
import Footer from "./components/Footer";
import Review from "./components/Reviews";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header />
      <Routes>
        {/*<Route path="/" element={<HomePage />} />
        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/dental-cleanings" element={<DentalCleanings />} />
        <Route path="/fillings" element={<Fillings />} />
        <Route path="/oral-surgery" element={<OralSurgery />} />
        <Route path="/root-canal" element={<RootCanal />} />
        <Route path="/crowns" element={<DentalCrown />} />
        <Route path="/your-first-visit" element={<YourFirstVisit />} />
        <Route path="/insurance" element={<Insurance />} />
        <Route path="/specials" element={<Specials />} />*/}
        <Route path="/" element={<AppointmentSlots />} />
        <Route path="/appt-slots" element={<AppointmentSlots />} />
        <Route path="/appt-details" element={<AppointmentDetails />} />
        <Route path="/appt-confirm" element={<AppointmentConfirmation />} />
        <Route path="/tnc" element={<TnC />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/*<Review/>*/}
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
