import Config from '../Config';
import AppointmentSlot from '../model/AppointmentSlot';
import axios from 'axios';

export default class ApptService {
    apiEndpoint : string = Config.base_url;
    endpoint : string = "appt";
    endpointNewAppt : string = "appt/new";
    endpointGetAppt : string = "appt";
    slotsPath : string = "slots";

    public async findAvailableSlots(busId : string, staffId: string,  date: any) : Promise<AppointmentSlot[]> {
        date = !date ? "" : date.getTime();
        try{
            let appointmentSlots : AppointmentSlot[] = new Array();
            const response = await axios.get(`${this.apiEndpoint}/${this.slotsPath}/${busId}/${staffId}?d=${date}`);
            
            if(Array.isArray(response.data)){
                response.data.forEach((res) => {
                    appointmentSlots.push(new AppointmentSlot(res));
                });
            }
            //console.log('slots response  ', appointmentSlots);
            return appointmentSlots;
        }catch(error) {
            console.error(error);
            return [];
        }
    }

    public async saveAppointment(apptDetails: any): Promise<any>{
        try{
            const response = await axios.post(`${this.apiEndpoint}/${this.endpointNewAppt}`, apptDetails);
            return response;
        }catch(error) {
            console.error(error);
            return {};
        }
    }

    
}