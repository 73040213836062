import React from "react";
import { Link } from "react-router-dom";
import "../assets/styles/footer.css";

const Footer = () => {
  return (
    <>
      <div className="container-fluid footer-container">
        <div className="container">
          <div className="row gy-5">
            <div className="col-12 d-flex justify-content-center d-block align-items-center">
              <div>
                <h2>AK DENTAL</h2>
                <div className="m-top-15">
                  <div>
                    7010 State Highway 71,
                  </div>
                  <div>
                    STE 225,
                  </div>
                  <div>
                    Austin, TX 78735.
                  </div>
                </div>
                <div className="m-top-15">
                  <div>
                    Phone : (512) 653 - 3008
                  </div>
                  <div>
                    Fax : (512) 653 - 3361
                  </div>
                </div>
                
              </div>
            </div>
            {/*<div className="col-lg-4 d-lg-flex justify-content-center d-block">
              <div>
                <div className="footer-book-button-container">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3447.0439843621243!2d-97.8770031851917!3d30.235822181813184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865b497cfd42a671%3A0x3b62e6549efba18e!2s7010%20State%20Hwy%2071%20Suite%20225%2C%20Austin%2C%20TX%2078735!5e0!3m2!1sen!2sus!4v1667696905345!5m2!1sen!2sus" 
                title="iframe"
                className="iframe"></iframe>
                </div>
              </div>
  </div>*/}
            {/*<div className="col-lg-4 d-lg-flex justify-content-center d-block">
              <div>
                <h5 className="mb-3">Office Hours</h5>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col">Monday</div>
                      <div className="col">10:30 AM - 6:00 PM</div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col">Tuesday</div>
                      <div className="col">10:30 AM - 6:00 PM</div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col">Wednesday</div>
                      <div className="col">10:30 AM - 6:00 PM</div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col">Thursday</div>
                      <div className="col">Closed</div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col">Friday</div>
                      <div className="col">Closed</div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col">Saturday</div>
                      <div className="col">Closed</div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col">Sunday</div>
                      <div className="col">Closed</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
          <div className="row">
            <div id="pipe-1" className="ct-text-block">|</div>
            <div className="col-12 d-flex align-items-center justify-content-center">
              <Link target="_top" to="/tnc" className="ct-link-text">Terms of Use</Link>
            </div>
            
          </div>
        </div>
      </div> 
      <div className="container-fluid footer-strip-container">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <span className="float-right">
                <a href='https://www.facebook.com/Akdentalpllc/'>
                  <i className="fa fa-facebook-official footer-strip-icon"
                    aria-hidden="true">
                  </i>
                </a>
                &nbsp;&nbsp; &nbsp;
                {/*<a href='https://www.facebook.com/Akdentalpllc/'>
                  <i
                    className="fa fa-instagram footer-strip-icon"
                    aria-hidden="true"
  ></i>}
                </a>*/}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
