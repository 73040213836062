import {
    Box,
    Button,
    FormControl,
    Grid,
    Typography,
    TextField
  } from "@mui/material";
import React, { useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ContentContainer from "../styledComponents/ContentContainer";
import ApptService from '../service/AppointmentService';
import validator from 'validator' 


function AppointmentConfirmation() {
    const [searchParams, setSearchParams] = useSearchParams();
    const apptTime = parseInt(searchParams.get('st'));
    const dentalReason = searchParams.get('rsn');
    const name = searchParams.get('name');
    
    return (
        <React.Fragment>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minHeight="100vh"
          >
            <ContentContainer>
              <Grid container pt={2}></Grid>
              <Grid container my={4} spacing={4}>
                <Grid item xs={12}>
                    <Typography component={Box} variant="h5">
                        Hello {name}, Your appointment with AK Dental is now scheduled!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <h4><u>Appointment Details</u></h4>
                    <p><b>When: </b>{moment(new Date(apptTime)).format("h:mm A on dddd, MMMM D YYYY")}</p>
                    <p><b>Why: </b> {dentalReason}</p>
                    <p><b>Where: </b>7010 State Highway 71, STE 225, Austin, TX 78735.</p>
                </Grid>
              </Grid>
            </ContentContainer>
          </Box>
        </React.Fragment>
      );
    
}

export default AppointmentConfirmation;
