import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography
  } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ApptService from '../service/AppointmentService';
import TimeSlot from "../components/TimeSlot";
import ContentContainer from "../styledComponents/ContentContainer";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";



function AppointmentSlots() {
    const [date, setDate] = useState();
    const [appointmentDate, setAppointmentDate] = useState();
    const [time, setTime] = useState();
    const [reason, setReason] = useState();
    const [reasonError, setReasonError] = useState(false);
    const [dateTimeError, setDateTimeError] = useState();
    const [appointmentSlots, setAppointmentSlots] = useState([]);
    const apptService = new ApptService();
    const navigate = useNavigate();

    const customDayRenderer = (
        eachDate,
        selectedDays,
        pickersDayProps
      ) => {
        let selected = false;
        //console.log("each date ", eachDate.date() +" "+eachDate.month());
        if (appointmentDate && appointmentDate.month()+","+appointmentDate.date() === eachDate.month()+","+eachDate.date()) {
            //console.log("date value ", appointmentDate);
            selected = true;
        }
        return (
          <PickersDay
            {...pickersDayProps}
            selected={selected}
          />
        )
      }
    
    function handleDateChange(date) {
        setDate(date);
        //console.log("date changed", date);
        setAppointmentDate(date);
        apptService.findAvailableSlots('ak-dental', 'dr-1', date.$d)
        .then(
            (slots) => {
                setAppointmentSlots(slots);
            }
        ); 
    }

    function handleReasonChange(reasonEvent) {
        //console.log("reason changed", reasonEvent);
        setReason(reasonEvent.target.value);
        
        if (reasonEvent.target.value !== undefined) {
            setReasonError(false);
            
        }
    }

    function handleSelectTime(selectedTime) {
        //console.log("Selected Time", selectedTime);
        setDateTimeError(false);
        setTime(selectedTime);
    }

    function handleContinue() {
        let error = false;
        if (reason === undefined) {
            //console.log("reason is not selected");
            setReasonError(true);
            error = true;
        }
        if (time === undefined) {
            //console.log("time is not selected");
            setDateTimeError(true);
            error = true;
        }
        if (error) {
            return;
        }
        navigate(`../appt-details?st=${time}&rsn=${reason}`);
    }

    function emptyTimeSlots() {
        //console.log("empty slots called");
        return (
            <Grid container >
                <Typography component={Box} variant="h6" mb={2} align="center">No Appointments available. Please select a different date.</Typography>
            </Grid>
        );    
    }

    function renderTimeSlots(slotsData) {
        //console.log("renderTimeSlots called");
        let slots = [];
        if (slotsData) {
            slotsData.map(
                ({slotTime, isAvailable, slotTimeInMillis}) => {
                    slots.push(<TimeSlot
                        key = {slotTimeInMillis}
                        time = {slotTime}
                        timeInMillis = {slotTimeInMillis}
                        selected={time}
                        onSelect={(selectedTime) => handleSelectTime(selectedTime)}
                        />);
                }
            )
        }
        return slots;
    }
    function renderErrors() {
        if (!dateTimeError && !reasonError) {
            return;
        }
        return (
                <>
                    {renderSelectReasonError()}
                    {renderDateTimeError()}
                </>
        );
    }

    function renderDateTimeError() {
        if (!dateTimeError) {
            return;
        }
        return (
            <Typography component={Box} variant="body1" align='center' color="error">
                Please select date and time for your appointment
            </Typography>
        );
    }

    function renderSelectReasonError() {
        if (!reasonError) {
            return;
        }
        return (
            <Typography component={Box} variant="body1" align='center' color="error">
                Please select the Reason for your appointment
            </Typography>
        );
    }

    useEffect(() => {
        apptService.findAvailableSlots('ak-dental', 'dr-1', date ? date.$d : "")
        .then(
            (slots) => {
                if (slots && slots.length > 0 ) {
                   let firstSlot = slots[0];
                   let availableDate = moment(parseInt(firstSlot.slotTimeInMillis));
                   //console.log("availableDate ", availableDate.date() +" "+availableDate.month());
                   setAppointmentDate(availableDate);
                }
                setAppointmentSlots(slots);
            }
        );
    }, []);
    
    return (
        <React.Fragment>
            <ContentContainer>
                <Grid container my={4}>
                <Grid item xs={12}>
                    <Typography component={Box} variant="h4" mb={4}>
                    AK Dental Appointments
                    </Typography>

                    <FormControl required fullWidth error={reasonError ? true: false}>
                    <InputLabel id="reason-select-label">
                        What's the reason for your visit?
                    </InputLabel>
                    <Select
                        labelId="reason-select-label"
                        id="reason-select"
                        value={reason}
                        onChange = {(e) => handleReasonChange(e)}
                        label="What's the reason for your visit?"
                    >
                        <MenuItem value={'New Patient Exam'}>New Patient Exam</MenuItem>
                        <MenuItem value={'Teeth Cleaning'}>Teeth Cleaning</MenuItem>
                        <MenuItem value={'Zoom Teeth Whitening'}>Zoom Teeth Whitening</MenuItem>
                        <MenuItem value={'Dental Consultation'}>Dental Consultation</MenuItem>
                        <MenuItem value={'Emergency Exam / Tooth Pain'}>Emergency Exam / Tooth Pain</MenuItem>
                    </Select>
                    </FormControl>

                    <Grid container spacing={2} mt={5}>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        display={"flex"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                    >
                        <Typography component={Box} variant="h6">
                        Select Date
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <CalendarPicker
                            minDate={moment().format("YYYY-MM-DD")}
                            date={date}
                            onChange={(nd) => handleDateChange(nd)}
                            renderDay={customDayRenderer}
                        />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Typography component={Box} variant="h6" mb={2}>
                        Select Time on <b>{appointmentDate && appointmentDate.format("dddd, MMMM D YYYY")}</b>
                        </Typography>
                        <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                            {appointmentSlots.length === 0 ? emptyTimeSlots() : renderTimeSlots(appointmentSlots)}
                        </Grid>
                    </Grid>
                    </Grid>
                    {renderErrors()}
                    <Grid container spacing={2} mt={2} mb={5}>
                    <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                        <Button fullWidth 
                                size="medium" 
                                variant="contained"
                                onClick={() => handleContinue()}
                        >
                            Continue
                        </Button>
                    </Grid>
                    </Grid>
                </Grid>
                </Grid>
            </ContentContainer>
        </React.Fragment>
    );
    
}

export default AppointmentSlots;
