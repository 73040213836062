import {
  Grid
} from "@mui/material";
import React from "react";

const colors = {
    primary: "#1B6075",
    black: "#000",
};

const TimeSlot = ({ onSelect, time, timeInMillis, selected }) => {
  return (
    <React.Fragment>
      <Grid item md={2} sm={3} xs={4}>
        <div
          style={{
            background: selected === timeInMillis ? colors.primary : "",
            color: selected === timeInMillis ? "#fff" : "#000",
            padding: "5px 0px",
            borderRadius: "5px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => onSelect(timeInMillis)}
        >
          {time}
        </div>
      </Grid>
      
    </React.Fragment>
  );
};

export default TimeSlot;
  

  