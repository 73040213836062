import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Grid,
    Typography,
    TextField,
    Autocomplete
  } from "@mui/material";
import React, { useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ContentContainer from "../styledComponents/ContentContainer";
import ApptService from '../service/AppointmentService';
import validator from 'validator' 


function AppointmentDetails() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [insurance, setInsurance] = useState();
    const [subscriberId, setSubscriberId] = useState("");
    const [dob, setDob] = useState("");
    const [instructions, setInstructions] = useState("");
    const apptService = new ApptService();
    const apptTime = parseInt(searchParams.get('st'));
    const dentalReason = searchParams.get('rsn');
    //errors
    const [userNameError, setUserNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [insuranceError, setInsuranceError] = useState(false);
    const [emailError, setEmailError] = useState("");
    const navigate = useNavigate();

    function handleUserNameChange(event) {
        let userName = event.target.value;
        setUserName(userName);
        setUserNameError(false);
    }

    function handlePhoneChange(event) {
        let phone = event.target.value;
        setPhone(phone);
        setPhoneError(false);
    }

    function handleSubscriberIdChange(event) {
        let subscriberId = event.target.value;
        setSubscriberId(subscriberId);
    }

    function handleDOBChange(event) {
        let dob = event.target.value;
        setDob(dob);
    }

    function handleInsuranceChange(event, newValue) {
        setInsurance(newValue);
        setInsuranceError(false);
    }

    function handleContinueToReview() {
        let error = false;
        if (!userName) {
            error = true;
            setUserNameError(true);
        }
        if (!phone || !validator.isMobilePhone(phone)) {
            error = true;
            setPhoneError(true);
        }
        if (!insurance) {
            error = true;
            setInsuranceError(true);
        }
        if (error) {
            return;
        }
        let apptDetails = {
            "user": {
                "UserEmail": email,
                "name": userName,
                "phone": phone,
                "insurance": {
                    "name" : insurance,
                    "subscriberId": subscriberId,
                    "dob": dob
                }
            },
            "appt": {
                "staffId": "dr-1",
                "busId": "ak-dental",
                "time": apptTime,
                "service": dentalReason
            }
        };
        apptService.saveAppointment(apptDetails)
        .then(
            (data) => {
                console.log("appointment created", data);
                navigate(`../appt-confirm?st=${apptTime}&rsn=${dentalReason}&name=${userName}`);
            }
        )
        .catch(
            (error) => {
                console.log("error creating appointment", error);
            }
        )
    }

    // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const insuranceList = [
    `I'll pay myself`, 
    'Aetna Dental PPO',
    'Beam Dental',
    'Blue Cross Blue Shield BlueCard PPO', 
    'Blue Cross Blue Shield of Texas BlueCare Dental',
    'Cigna Dental PPO',
    'Delta Dental PPO',
    'Delta Dental PPO Plus Premier', 
    'Humana-Careington Dental Plan',
    'Lincoln Financial Group DentalConnect (PPO)',
    'Medicaid',
    'MetLife PDP',
    'UnitedHealthcare Dental PPO'
];
    return (
        <React.Fragment>
            <ContentContainer>
                <Grid container my={4}>
                    <Grid item xs={12}>
                        <h3>Appointment Details</h3>
                        <p><b>When: </b>{moment(new Date(apptTime)).format("h:mm A on dddd, MMMM Do YYYY")}</p>
                        <p><b>Visit Reason: </b> {dentalReason}</p>
                        <p><b>Where: </b>7010 State Highway 71, STE 225, Austin, TX 78735.</p>
                    </Grid>
                    
                    <Grid component={"form"} container spacing={1}>
                    <Grid item xs={12}>
                        <Typography component={Box} variant="h5" mb={1}>
                            Your contact details
                        </Typography> 
                    </Grid>                  
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                        <TextField
                            required
                            id="name"
                            label="First & Last Name"
                            variant="outlined"
                            value={userName}
                            error={userNameError ? true: false}
                            helperText={userNameError ? "Please enter your Name": ""}
                            onChange={(e) => handleUserNameChange(e)}
                        />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                        <TextField
                            id="email"
                            label="Email"
                            type="email"
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                        <TextField
                            required
                            id="phone"
                            label="Phone"
                            variant="outlined"
                            value={phone}
                            type="tel"
                            error={phoneError ? true: false}
                            helperText={phoneError ? "Please enter a Phone number to reach you.": ""}
                            onChange={(e) => handlePhoneChange(e)}
                        />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl required fullWidth error={insuranceError ? true: false}>
                        {/*<InputLabel id="reason-select-label">
                            Dental Insurance
                        </InputLabel>
                        <Select
                            labelId="reason-select-label"
                            id="reason-select"
                            value={insurance}
                            onChange = {(e) => handleInsuranceChange(e)}
                            label="Dental Insurance Details"
                        >
                            <MenuItem value={'noInsurance'}>No Insurance</MenuItem>
                            <MenuItem value={'aetna'}>Aetna Dental</MenuItem>
                            <MenuItem value={'bcbs'}>Blue Cross Blue Shield Dental</MenuItem>
                            <MenuItem value={'cigna'}>Cigna Dental</MenuItem>
                            <MenuItem value={'delta'}>Delta Dental PPO / Premium</MenuItem>
                            <MenuItem value={'guardian'}>Guardian Dental</MenuItem>
                            <MenuItem value={'metlife'}>Metlife Dental</MenuItem>
    </Select>*/}
                        <Autocomplete
                            id="insurance-select"
                            freeSolo
                            options={insuranceList}
                            renderInput={(params) => <TextField required {...params} label="Insurance Details" InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }} error={insuranceError ? true: false}/>}
                            onChange = {(e, nv) => handleInsuranceChange(e, nv)}
                        />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                        <TextField
                            id="subscriberId"
                            label="Subscriber Id (Optional)"
                            variant="outlined"
                            value={subscriberId}
                            onChange={(e) => handleSubscriberIdChange(e)}
                        />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                        <TextField
                            id="dob"
                            label="Subscriber Date Of Birth (MM/DD/YYYY) (Optional)"
                            variant="outlined"
                            value={dob}
                            onChange={(e) => handleDOBChange(e)}
                        />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                        <TextField
                            id="instructions"
                            label="Instructions"
                            variant="outlined"
                            multiline
                            rows={4}
                            value={instructions}
                            onChange={(e) => setInstructions(e.target.value)}
                        />
                        </FormControl>
                    </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={2} mb={5}>
                        <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                            <Button fullWidth 
                                    size="medium" 
                                    variant="contained"
                                    onClick={() => handleContinueToReview()}
                            >
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                
                </Grid>
            </ContentContainer>
        </React.Fragment>
    );
    
}

export default AppointmentDetails;
