import moment from "moment";

export default class AppointmentSlot{
    slotTime : string;
    isAvailable : boolean;
    slotTimeInMillis : number;

    public constructor(src?: any){
        if(src){
            this.map(src);
        }
    }

    public map(src: any){
        //console.log("while mapping ",src.timeInMillis);
        
        this.slotTime = moment(new Date(parseInt(src.timeInMillis)), "DD-MM-YYYY hh:mm:ss a -ZZ").format("h:mm A");
        this.isAvailable = src.available;
        this.slotTimeInMillis = src.timeInMillis;
    }
}