import { Box, styled } from "@mui/material";

const ContentContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    margin: "5%",
  },
  [theme.breakpoints.up("md")]: {
    width: "900px",
    margin: "0 auto",
  },
}));

export default ContentContainer;