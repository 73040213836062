import React, { useState, useEffect } from "react";

//importing css
import "../assets/styles/header.css";
//importing the logo
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";

const Header = () => {
  const [menuBar, setMenuBar] = useState(false);
  const [stickyHeader, setStickyHeader] = useState(false);
  const [mouseOut] = useState(null);

  const stickyNavbar = () => {
    if ((window.scrollY > 100) & (window.innerWidth > 1024)) {
      setStickyHeader(true);
    } else {
      setStickyHeader(false);
    }
  };

  // to control onmouseout click to close dental services dropdown
  useEffect(() => {
    const handleDropDownClose = (e) => {
      e.stopPropagation();
      const dropDown = document.querySelector(".dental-services-list");
      dropDown.style.display = "none";
      document.removeEventListener("click", handleDropDownClose);
    };

    if (mouseOut === true) {
      document.addEventListener("click", handleDropDownClose);
    } else if (mouseOut === false) {
      document.removeEventListener("click", handleDropDownClose);
    }
  }, [mouseOut]);

  window.addEventListener("scroll", stickyNavbar);

  return (
    <>
      <nav className={stickyHeader ? "sticky-nav" : null}>
        <div className="nav-container">
        {/*<Link target="_top" to="/" className="link-2">*/}
        <a href="https://www.akdentalatx.com" className="link-2">
            <div className="logo-container">
              <img src={logo} alt="logo" className="logo" />
              <h1 className="logo-name">AK Dental</h1>
            </div>
        </a>

          <div className="menu-container">
            <ul
              className="nav-items-container"
            >
              {/*<Link target="_top" to="/our-team" className="link-2">
                <li className="general-container nav-main-item-name">
                  <span className="nav-main-items">Our Team</span>
                </li>
              </Link>
              <li
                className="dental-services-container nav-main-item-name"
              >
                <span className="nav-main-items header-hover-tip m-1">Dental Services</span>
                <i className="material-symbols-outlined nav-arrowdown-img">
                  expand_more
                </i>
                <ul className="dental-services-list">
                  <Link target="_top" to="/dental-cleanings" className="link-2">
                    <li className="header-hover-tip">Dental Cleaning</li>
                  </Link>
                  <Link target="_top" to="/fillings" className="link-2">
                    <li className="header-hover-tip">Dental Fillings</li>
                  </Link>
                  <Link target="_top" to="/oral-surgery" className="link-2">
                    <li className="header-hover-tip">Tooth Extractions</li>
                  </Link>
                  <Link target="_top" to="/root-canal" className="link-2">
                    <li className="header-hover-tip">Root Canal</li>
                  </Link>
                  <Link target="_top" to="/crowns" className="link-2">
                    <li className="header-hover-tip">Dental Crowns</li>
                  </Link>
                </ul>
              </li>
              <Link target="_top" to="/your-first-visit" className="link-2">
                <li className="general-container nav-main-item-name">
                  <span className="nav-main-items">Your First Visit</span>
                </li>
              </Link>
              <Link target="_top" to="/insurance" className="link-2">
                <li className="general-container nav-main-item-name">
                  <span className="nav-main-items">Insurance</span>
                </li>
              </Link>
              <Link target="_top" to="/specials" className="link-2">
                <li className="general-container nav-main-item-name">
                  <span className="nav-main-items">Specials</span>
                </li>
              </Link>*/}
              
              <Link target="_top" to="/appt-slots" 
                    className="btn btn-primary header-book-button header-book-button-container link-2" 
                    role="button">      
                <li className="general-container nav-main-item-name">
                  <span className="nav-main-items header-book-button-color">Book Online</span>
                </li>
              </Link>
              
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;